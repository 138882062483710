import { Environment } from '../../../config';
import request from '../../../utils/request';
import axios from 'axios';
import * as Types from '../types';
import store from '../../../redux/store';
import { showToastAction } from '../../../components/atoms/toast_message';
import _ from 'lodash';
import AuthActions from 'redux/reducers/gl_auth_reducer/actions';
import { ClientError } from 'utils/types';
import { jwtDecode } from "jwt-decode";


/** authenticateUser Endpoint */
interface IAuthenticateInput {
  email: string;
  password: string;
}

export interface IAuthenticateData {
  access_token: string;
  user: {
    uuid: string;
    roles: Array<string | number>;
  };
}

interface IAuthenticateOutput extends Types.ResponseOutput {
  data: IAuthenticateData;
}

export const authenticateUser = async (
  data: IAuthenticateInput
): Promise<IAuthenticateOutput> => {
  const result: any = await request(
    'post',
    `${Environment.API_BASE_URL}/api/oauth/login`,
    {
      body: {
        email: data.email,
        password: data.password,
      },
      showToastMessage: true,
    }
  );

  // ? result.data.data.user.role.map(({ role_id }: any) => role_id)
  result.data.data.user.roles = Array.isArray(result.data.data?.user?.role)
    ? result.data.data.user.role.map((role_id: string | number) => role_id)
    : [];

  return result.data;
};

interface IForgotPassword {
  email: string;
}

export const forgotPassword = async (data: IForgotPassword): Promise<any> => {
  const result: any = await request(
    'post',
    `${Environment.API_BASE_URL}/api/oauth/forgot-password`,
    {
      body: {
        email: data.email,
      },
    }
  );
  return result.data;
};

interface IResetPasswordInput {
  password: string;
  confirm_password: string;
  token: string;
}
export const resetPassword = async (
  input: IResetPasswordInput
): Promise<Types.ResponseOutput> => {
  try {
    // console.log(input.token);
    // const instance = axios.create();
    const { data } = await axios({
      method: 'patch',
      url: `${Environment.API_BASE_URL}/api/oauth/change-password`,
      data: {
        password: input.password,
        confirm_password: input.confirm_password,
      },
      headers: {
        'x-access-token': input.token,
      },
    });

    let output: Types.ResponseOutput = {
      message: data.message,
      data: data.data,
      status: data.status,
    };
    return output;
  } catch (error) {
    showToastAction(
      store.dispatch,
      _.get(error, 'response.data.message', error.message),
      'error'
    );
    throw new Error(error.message);
  }
};

interface IChangePasswordInput {
  newPassword: string;
  confirmPassword: string;
  oldPassword: string;
}
export const changePassword = async (
  input: IChangePasswordInput
): Promise<Types.ResponseOutput> => {
  const result: any = await request(
    'patch',
    `${Environment.API_BASE_URL}/api/oauth/update-password`,
    {
      body: {
        new_password: input.newPassword,
        confirm_new_password: input.confirmPassword,
        old_password: input.oldPassword,
      },
      showToastMessage: true,
    }
  );

  let output: Types.ResponseOutput = {
    message: result.data.message,
    data: result.data.data,
    status: result.data.status,
  };
  return output;
};

/** reloadAuthData Endpoint */
interface IGetUserDataInput {
  token?: string;
  refreshToken?: string;
}

interface IGetUserDataOutput extends Types.ResponseOutput {
  data: {
    roles: Array<string>;
    username: string;
    email: string;
    id: string;
  };
}

export const getUserData = async (
  data: IGetUserDataInput
): Promise<IGetUserDataOutput> => {
  try {
     
    const result = await request(
      'get',
      `${Environment.API_BASE_URL}/api/oauth/refresh-data`,
      {
        body: null,
        useMiddleware: data.token ? false : true,
        headers: {
          ...(data.token ? { 'x-access-token': data.token } : undefined),
        },
      }
    );
    
    const decoded = {...result.data, data: jwtDecode(result.data.data)};
    // console.log(decoded);
    return decoded as IGetUserDataOutput;
  } catch (error: any) {
    const _error = error as ClientError;
    if (_error.statusCode !== 401) {
      // logout
      // alert('ALERT???????');
      store.dispatch(AuthActions.logoutAction());
    }
    throw new Error(error);
  }
};
