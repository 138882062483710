import { IFetchSchoolOptionsData } from 'services/api/school';
import { IFetchUserOptionsData } from 'services/api/user';
import { IVisitsList } from 'services/api/visits';
import {
  IAction,
  KEEP_THE_SAME,
  StateStatus,
} from '../../../redux/utils/common';
import * as Types from './types';
import { IPostalCodesData } from 'services/api/postal_codes';

export interface IState {
  open: boolean;
}

const initialState: IState = {
  open: false,
};

const VisitReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.SET_OPEN:
      console.log('setting open');
      return {
        ...state,
        open: action.data,
      };
    case Types.TOGGLE:
      console.log('toggling open');
      return {
        ...state,
        open: !state.open,
      };
    default:
      return {
        ...state,
      };
  }
};

export default VisitReducer;
