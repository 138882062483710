import { IAction, KEEP_THE_SAME, StateStatus } from 'redux/utils/common';
import { ICommunicationDetailData } from './actions';
import * as Types from './types';

export interface IState {
  CommunicationsList: {
    status: StateStatus | null;
    data: any;
  };
  viewCommunications: {
    status: StateStatus | null;
    data: any;
  };
}

const initializeState: () => IState = () => {
  return {
    CommunicationsList: {
      status: null,
      data: null,
    },
    viewCommunications: {
      status: null,
      data: {
        id: 'new',
        foreign_id: null,
        foreign_type: null,
        type: null,
        incoming: '0',
        subject: null,
        status: null,
        priority: null,
        date_start: new Date(),
        date_end: new Date(),
        processed_date: new Date(),
        comments: null,
        actions: null,
        assigned_user: null,
        processed_user: null,
        modified_at: null,
        modified_by: null,
        created_at: null,
        created_by: null,
        userid: null,
      },
    },
  };
};

const initialState: IState = initializeState();

const CommunicationsReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.SET_COMMUNICATIONS_LIST:
      return {
        ...state,
        CommunicationsList: {
          ...state.CommunicationsList,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.CommunicationsList.data
              : action.data,
        },
      };
    case Types.SET_COMMUNICATIONS_DETAIL:
      // console.log(action.data);
      return {
        ...state,
        viewCommunications: {
          ...state.viewCommunications,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.viewCommunications.data
              : action.data,
        },
      };
    case Types.CLEAR_STORE:
      let clearedState: IState = initializeState();
      clearedState = {
        ...clearedState,
        viewCommunications: {
          ...clearedState.viewCommunications,
          status: StateStatus.Success,
        },
      };
      return { ...clearedState };
    default:
      return { ...state };
  }
};

export default CommunicationsReducer;
