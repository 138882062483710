import { IFetchSchoolOptionsData } from 'services/api/school';
import { IFetchUserOptionsData } from 'services/api/user';
import { IVisitsList } from 'services/api/visits';
import {
  IAction,
  KEEP_THE_SAME,
  StateStatus,
} from '../../../redux/utils/common';
import * as Types from './types';
import { IPostalCodesData } from 'services/api/postal_codes';

export interface IState {
  visitsList: {
    data: IVisitsList | null;
    status: StateStatus | null;
  };
  schoolOptions: {
    data: IFetchSchoolOptionsData;
    status: StateStatus | null;
  };
  userOptions: {
    data: IFetchUserOptionsData;
    status: StateStatus | null;
  };
  postalCodes: {
    data: IPostalCodesData | null;
    status: StateStatus | null;
  };
}

const initialState: IState = {
  visitsList: {
    data: null,
    status: null,
  },
  schoolOptions: {
    data: [],
    status: null,
  },
  userOptions: {
    data: [],
    status: null,
  },
  postalCodes: {
    data: [],
    status: null,
  },
};

const VisitReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.SEARCH_VISITS:
      return {
        ...state,
        visitsList: {
          ...state.visitsList,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME ? state.visitsList.data : action.data,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default VisitReducer;
