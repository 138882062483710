interface Environment {
  API_BASE_URL: string;
  APP_NAME: string;
  STATIC_FILES_URL: string;
}

const Prodution: Environment = {
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL || '',
  APP_NAME: 'KOA',
  STATIC_FILES_URL: process.env.REACT_APP_API_BASE_URL || '/static',
};

const Development: Environment = {
  // 'https://koa.cellock.com',
  API_BASE_URL: 'http://localhost:1337', //http://192.168.2.23:1337
  APP_NAME: 'KOA',
  STATIC_FILES_URL: 'http://localhost:1337/static',
};

export default process.env.NODE_ENV === 'production' ? Prodution : Development;
