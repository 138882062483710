import { typeCreatorV2 } from 'redux/utils/common';

//  [REDUCER][TYPE]
export const SET_COMMUNICATIONS_LIST: string = typeCreatorV2(
  'CommunicationsReducer',
  'SetCommunicationsList'
);

export const SET_ADD_COMMUNICATIONS: string = typeCreatorV2(
  'CommunicationsReducer',
  'SetAddCommunications'
);

export const SET_EDIT_COMMUNICATIONS: string = typeCreatorV2(
  'CommunicationsReducer',
  'SetEditCommunications'
);

export const SET_COMMUNICATIONS_DETAIL: string = typeCreatorV2(
  'CommunicationsReducer',
  'SetCommunicationsDetail'
);

export const CLEAR_STORE: string = typeCreatorV2(
  'CommunicationsReducer',
  'ClearCommunicationsStore'
);
