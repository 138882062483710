import {
  IAction,
  KEEP_THE_SAME,
  StateStatus,
} from '../../../redux/utils/common';
import * as Types from './types';

export interface IState {
  UsersList: {
    status: StateStatus | null;
    data: any;
  };
  SearchOutput: {
    status: StateStatus | null;
    data: any;
  };
  addUsers: {
    status: StateStatus | null;
  };
  editUsers: {
    status: StateStatus | null;
  };
  viewUsers: {
    status: StateStatus | null;
    data: any;
  };
  editUserRole: {
    data: Array<{ current: number; previous: number | null }>;
    statuses: Array<StateStatus | null>;
  };
}

const initialState: IState = {
  UsersList: {
    status: null,
    data: null,
  },
  SearchOutput: {
    status: null,
    data: null,
  },
  addUsers: {
    status: null,
  },
  editUsers: {
    status: null,
  },
  viewUsers: {
    status: null,
    data: null,
  },
  editUserRole: {
    data: [],
    statuses: [],
  },
};

const UsersReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.SET_USERS_LIST:
      return {
        ...state,
        UsersList: {
          ...state.UsersList,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME ? state.UsersList.data : action.data,
        },
      };
    case Types.SEARCH_USERS:
      let newEditStatuses: Array<StateStatus | null> = [];
      let newData: Array<{ current: number; previous: number | null }> = [];
      if (action?.data?.items?.length && action.data.items.length > 0) {
        newEditStatuses = new Array<StateStatus | null>(
          action.data.items.length
        );
        newEditStatuses.fill(null);
        newData = new Array<{ current: number; previous: number | null }>(
          action.data.items.length
        );
        (action.data.items as []).map((value: any, index, array) => {
          return (newData[index] = { previous: null, current: value.Role });
        });
      }

      return {
        ...state,
        SearchOutput: {
          ...state.SearchOutput,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME ? state.UsersList.data : action.data,
        },
        editUserRole: {
          data: newData,
          statuses: newEditStatuses,
        },
      };
    case Types.SET_ADD_USERS:
      return {
        ...state,
        addUsers: {
          ...state.addUsers,
          status: action.status || null,
        },
      };
    case Types.SET_EDIT_USERS:
      return {
        ...state,
        editUsers: {
          ...state.editUsers,
          status: action.status || null,
        },
      };
    case Types.SET_ROLE:
      let new_data = state.editUserRole.data;
      if (action.status === StateStatus.Success) {
        new_data[action.data.index].previous =
          new_data[action.data.index].current;
        new_data[action.data.index].current = action.data.value;
      }

      //slice required for shallow compare
      const new_statuses = state.editUserRole.statuses.slice();
      if (action?.data?.index !== undefined && action?.status !== undefined) {
        new_statuses[action.data.index] = action.status;
      }

      return {
        ...state,
        editUserRole: {
          data: new_data,
          statuses: new_statuses,
        },
      };
    case Types.SET_USERS_DETAIL:
      return {
        ...state,
        viewUsers: {
          ...state.viewUsers,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME ? state.viewUsers.data : action.data,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default UsersReducer;
